<template>
  <div class="page-wrapper bg-blue-dark-500 py-2">
    <div class="pt-5 p-3 text-center">
      <!-- <img src="../assets/logo.png" alt="logo" /> -->
      <img
        :src="$appLogo"
        alt="logo"
        style="height: 87px;object-fit: contain;"
      />
    </div>

    <b-row class="no-gutters justify-content-center my-5">
      <b-card no-body class="form-wrapper w-100 mx-3 border-0 large-wrapper">
        <b-card-body class="p-4">
          <div class="h3 text-center">
            {{ $t("warning") }}
          </div>
          <div
            class="pt-2 text-center"
            v-html="
              $t('page.tokenRecovery.tokenWarning', {
                mail: $route.params.email
              })
            "
          ></div>
          <b-button
            :disabled="clicked"
            class="text-white float-right mt-3"
            @click="recoverToken"
          >
            {{ $t("button.send") }}
          </b-button>
        </b-card-body>
        <template v-slot:footer>
          <div class="text-center card-footer">
            <b-link
              class="text-uppercase text-white b-link"
              @click="$router.replace('login')"
            >
              {{ $t("button.backToLogin") }}
            </b-link>
          </div>
        </template>
      </b-card>
    </b-row>
    <h6 class="text-white text-center">v{{ appVersion }}</h6>
  </div>
</template>

<script>
import { appMixin } from "../mixins";
export default {
  mixins: [appMixin],
  data() {
    return {
      clicked: false
    };
  },
  computed: {
    appVersion() {
      return this.$store.getters["info/getAppVersion"];
    }
  },
  methods: {
    async recoverToken() {
      try {
        this.clicked = true;
        await this.$store.dispatch("auth/identityConfirmPost", {
          tool: "email",
          payload: { identityId: this.$route.params.email }
        });
        setTimeout(() => {
          this.clicked = false;
        }, 10000);
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  min-height: calc(100vh);
}
.form-wrapper {
  max-width: 440px;
  min-height: 100px;
  transition: all 0.6s ease-in-out;
}
.large-wrapper {
  max-width: 440px;
}
.card-footer {
  border-top: unset;
  padding: 8px;
  background-color: var(--blue-dark-400);
  .b-link {
    opacity: 0.7;
  }
}
</style>
